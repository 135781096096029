<template>
	<el-table class="jscx-table" height="auto" ref="multipleTable" :data="tableData" @select="pinSelect"
		@sort-change="$emit('sortChange', $event)" size="mini" border @selection-change="handleSelectionChange"
		:header-row-style="headerRowStyle" :cell-style="cellStyle" :row-style="rowStyle">
		<el-table-column v-for="tableColumn, index in tableColumns" :label="tableColumn.label" :prop="tableColumn.prop"
			:min-width="tableColumn.width || 100" align="center" :sortable="tableColumn.sortable"
			:formatter="cellFormatter">
			<template slot-scope="scope">
				<template v-if="scope.column.property==='autoAdjust'">
					<el-switch
					  v-model="scope.row.autoAdjust"
					  active-text="✓"
					  inactive-text="✗"
					  @change="updateAutoAdjstStatus && updateAutoAdjstStatus(scope.row)">
					</el-switch>
				</template>
				<template v-else-if="scope.column.property==='planType'">
					<plan-type-popover :id="scope.row.id" :planType.sync="scope.row.planType"></plan-type-popover>
				</template>
				<template v-else>
					{{cellFormatter(scope.row, scope.column, scope.row[scope.column.property], scope.$index)}}
				</template>
			</template>
		</el-table-column>
	</el-table>
</template>

<script>
	import MultipleTableSelection from '@/common/mixins/multiple-table-selection.js'
	import PlanTypePopover from './plan-type-popover'
	import {
		mapGetters
	} from 'vuex'
	import Moment from 'moment'
	export default {
		mixins: [MultipleTableSelection],
		components: {
			PlanTypePopover
		},
		props: {
			tableData: {
				type: Array,
				default: []
			},
			tableColumns: {
				type: Array,
				default: []
			},
			updateAutoAdjstStatus: {
				type: Function,
				default: null
			}
		},
		methods: {
			doLayout() {
				this.$refs.multipleTable.doLayout()
			},
			cellFormatter(row, column, cellValue, index) {
				if (/(cost)|(revenue)|(cpc)|(epc)/i.test(column.property)) {
					return Number(cellValue || null).toFixed(2)
				} else if (/(conversion)/i.test(column.property)) {
					return Number(cellValue || null).toFixed(0)
				} else if (/direction/i.test(column.property)) {
					return cellValue || 'Others'
				}
				return cellValue
			},
			headerRowStyle({
				row,
				column,
				rowIndex,
				columnIndex
			}) {
				return {
					'font-size': '10px',
					'text-transform': 'capitalize'
				}
			},
			cellStyle({
				row,
				column,
				rowIndex,
				columnIndex
			}) {
				return {}
			},
			rowStyle({
				row,
				rowIndex
			}) {
				return {
					'font-size': '10px'
				}
			}
		}
	}
</script>

<style>
	.jscx-table.el-table .el-table__body tr:hover>td.el-table__cell {
		background-color: unset;
	}
</style>