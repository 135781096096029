<template>
	<el-popover placement="bottom-start" width="300" trigger="hover" @show="onShow">
		<div v-if="loading" v-loading="loading" class="blank-container"></div>
		<div v-else class="blank-container">
			<template v-if="this.planTypes.length === 0">
				<el-button type="text" @click="onShow" size="mini">刷新</el-button>
			</template>
			<template v-else>
				<el-radio-group v-model="curPlanType" size="mini" @input="changeInput">
					<el-radio-button v-for="item in planTypes" :label="item" :key="item"></el-radio-button>
				</el-radio-group>
			</template>
		</div>
		<div slot="reference">{{planType || '未配置'}}</div>
	</el-popover>
</template>

<script>
	import {
		mapActions
	} from 'vuex'
	import Moment from 'moment'
	export default {
		props: {
			id: {
				type: String,
				default: ''
			},
			planType: {
				type: String,
				default: ''
			}
		},
		inject: ['adminLayout'],
		data() {
			return {
				loading: false,
				planTypes: [],
				curPlanType: '',
				prePlanType: ''
			}
		},
		watch: {
			curPlanType(newVal, oldVal) {
				this.prePlanType = oldVal
			}
		},
		methods: {
			...mapActions('jscx', [
				'fetchJSCXListTermPlanTypes',
				'updateJSCXChangeTermPlanType'
			]),
			onShow() {
				if (this.planTypes.length === 0 && !this.loading) {
					this.loading = true
					const params = {
						term: this.term
					}
					this.fetchJSCXListTermPlanTypes({
							params
						})
						.then(planTypes => {
							this.curPlanType = this.planType || ''
							this.planTypes = planTypes
						})
						.finally(() => {
							this.loading = false
						})
				}
			},
			changeInput() {
				this.$nextTick(() => {
					this.$confirm(`此操作将 ${this.prePlanType} 改为 ${this.curPlanType}, 是否继续?`, '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {
						this.adminLayout.showLoading()
						this.updateJSCXChangeTermPlanType({offerId: this.id, planType:this.curPlanType})
						.then(res => {
							this.adminLayout.hideLoading()
							this.$emit('update:planType', this.curPlanType)
						})
						.catch(err => {
							this.adminLayout.hideLoading()
						})
					}).catch(() => {this.curPlanType = this.prePlanType})
				})
			}
		}
	}
</script>

<style scoped>
	.blank-container {
		height: 40px;
		text-align: center;
	}
</style>