<template>
	<el-card :class="fullScreen ? 'plan-full-screen': ''">
		<advanced-search-bar>
			<template>
				<form-item label="选择日期:" style="width:300px;">
					<el-date-picker v-model="queryForm.date" type="daterange" start-placeholder="开始日期"
						end-placeholder="结束日期" value-format="yyyy-MM-dd" style="width:340px;" size="mini"
						:clearable="false">
					</el-date-picker>
				</form-item>
				<form-item>
					<el-button type="primary" size="mini" icon="el-icon-search" @click="searchEvent">查 询</el-button>
					<el-button type="primary" size="mini" @click="downloadTask">下 载</el-button>
					<el-button type="primary" @click="showEdit" size="mini">显示编辑</el-button>
					<el-button type="primary" @click="fullScreen=!fullScreen" size="mini">{{ fullScreen ? '缩小':'全屏'}}</el-button>
				</form-item>
			</template>
		</advanced-search-bar>
		<div style="background-color:#DCDFE6;height:1px;margin:0 0 24px -20px;width:calc(100% + 40px);">
		</div>
		<offer-table ref="planTable" class="w-100" :tableData="tableData" :tableColumns="filterTableColumns"
			 @handleSelectionChange="handleSelectionChange" :updateAutoAdjstStatus="updateAutoAdjstStatus"></offer-table>
		<!-- <el-pagination class="mt-3" style="text-align:right;" @size-change="handleSizeChange"
			@current-change="handleCurrentChange" :current-page.sync="page.current" :page-size="page.size"
			:page-sizes="page.sizes" layout="prev, pager, next, sizes, jumper" :total="page.total">
		</el-pagination> -->
		<table-columns-drawer :visible.sync="drawer.edit" :data="sortedTableColumns" :checkedKeys.sync="checkedKeys"
			:sortedKeys.sync="sortedKeys" :namespace="namespace + '_offers'"
			:updateColumns="updateColumns"></table-columns-drawer>
	</el-card>
</template>

<script>
	import OfferTable from '@/components/jscx/offer-table.vue'
	import config from './minixs/offers.js'
	import Moment from 'moment'
	import TableColumnsDrawer from '@/components/jscx/table-columns-drawer.vue'
	export default {
		mixins: [config],
		components: {
			OfferTable,
			TableColumnsDrawer
		},
		inject: ['adminLayout'],
		data() {
			return {
				fullScreen: false,
				timeout: null,
				sortedKeys: [],
				checkedKeys: [],
				namespace: 'jscx',
				drawer: {
					edit: false
				},
				tableColumns: [{
						label: 'Offer Name',
						prop: 'offerName',
						disabled: false,
						sortable: false,
						width: 95
					}, {
						label: "Direction",
						prop: "direction",
						disabled: false,
						sortable: false,
						width: 78
					},
					{
						label: "Domain Name",
						prop: "domainName",
						disabled: false,
						sortable: false,
						width: 80
					},
					{
						label: "cost",
						prop: "cost",
						disabled: false,
						sortable: true,
						width: 60
					},
					{
						label: "conversion",
						prop: "conversion",
						disabled: false,
						sortable: true,
						width: 60
					},
					{
						label: "ads-conversion",
						prop: "adsConversion",
						disabled: false,
						sortable: true,
						width: 60
					},
					{
						label: "rev",
						prop: "estimatedRevenue",
						disabled: false,
						sortable: true,
						width: 60
					},
					{
						label: "cpc",
						prop: "cpc",
						disabled: false,
						sortable: true,
						width: 60
					},
					{
						label: "epc",
						prop: "epc",
						disabled: false,
						sortable: true,
						width: 60
					},
					{
						label: "Plan Type",
						prop: 'planType',
						disabled: false,
						width: 60
					},
					{
						label: "是否自动调整",
						prop: "autoAdjust",
						disabled: true,
						sortable: false,
						width: 100
					}
				],
				sortedTableColumns: [],
				filterTableColumns: []
			}
		},
		created() {
			const checkedKeys = localStorage.getItem(`${this.namespace}_offers_${process.env.VUE_APP_VERSION}_checked_keys`)
			if (checkedKeys) {
				try {
					this.checkedKeys = JSON.parse(checkedKeys)
				} catch (e) {
					console.log(e)
				}
			} else {
				this.checkedKeys = this.tableColumns.map(v => v.prop)
			}
			const sortedKeys = localStorage.getItem(`${this.namespace}_offers_${process.env.VUE_APP_VERSION}_sorted_keys`)
			if (sortedKeys) {
				try {
					this.sortedKeys = JSON.parse(sortedKeys)
				} catch (e) {
					console.log(e)
				}
			} else {
				this.sortedKeys = this.tableColumns.map(v => v.prop)
			}
			this.updateColumns()
		},
		watch: {
			fullScreen(newVal, oldVal) {
				this.$nextTick(() => {
					this.$refs.planTable.doLayout()
				})
			}
		},
		methods: {
			updateColumns() {
				this.sortedTableColumns = this.tableColumns.reduce((lhs, rhs) => {
					const index = this.sortedKeys.indexOf(rhs.prop)
					if (index !== -1) {
						lhs[index] = rhs
					}
					return lhs
				}, new Array(this.tableColumns.length))
				this.filterTableColumns = this.sortedTableColumns.reduce((lhs, rhs) => {
					if (this.checkedKeys.indexOf(rhs.prop) !== -1) {
						lhs.push(rhs)
					}
					return lhs
				}, [])
			},
			showEdit() {
				this.drawer.edit = true
			},
			updateAutoAdjstStatus(row) {
				this.adminLayout.showLoading()
				this.$store.dispatch(`${this.namespace}/updateJSCXCFOfferStatus`, {id: row.id, flag: row.autoAdjust ? '1' : '0'})
					.then(res => {
						this.adminLayout.hideLoading()
						try {
							this.$showSuccess(res)
						} catch (err) {}
					})
					.catch(error => {
						row.autoAdjust = !row.autoAdjust
						this.adminLayout.hideLoading()
						this.$showErrMsg(error)
					})
			}
		}
	}
</script>

<style>
	.plan-full-screen {
		position: fixed;
		top: 0;
		left: 0;
		width: 100vw;
		height: 100vh;
	}
</style>